import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/css-modules",
  "date": "2015-12-08",
  "title": "CSS MODULES",
  "author": "admin",
  "tags": ["development", "javascript", "css", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "A CSS Module is a CSS file in which all class names and animation names are scoped locally by default, eleminating the problems with global nature of the CSS namespace."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can use a module bundler such as `}<strong parentName="p">{`webpack`}</strong>{` to load CSS scoped to a particular document.`}</p>
    <Message type="info" title="CSS Module Loaders" content="CSS module loader will generate a unique name for a each CSS class at the time of loading the CSS document (Interoperable CSS to be precise)." mdxType="Message" />
    <h2>{`Why CSS Modules?`}</h2>
    <p>{`CSS by default has global scope. This can lead into numerous issues:`}</p>
    <ul>
      <li parentName="ul">{`Global namespace - Selectors can clash with other selectors targeting same elements.`}</li>
      <li parentName="ul">{`The specificity wars can break your selctors.`}</li>
      <li parentName="ul">{`Forced to use `}<strong parentName="li">{`!important`}</strong>{` to override element level styles.`}</li>
    </ul>
    <p>{`With css-modules, we have a way to emulate local scope and control dependencies in front-end components.`}</p>
    <p>{`CSS Modules compile to a low-level interchange format called ICSS or `}<inlineCode parentName="p">{`nteroperable CSS`}</inlineCode>{`, but are written like normal CSS files:`}</p>
    <p>{`Before CSS Modules:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* button.css */
.button-normal {
  color: blue;
}
.button-error {
  color: red;
}
.button-disabled {
  color: grey;
}
`}</code></pre>
    <p>{`With CSS Modules:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* button.css */
.normal {
  color: blue;
}
.error {
  color: red;
}
.disabled {
  color: grey;
}
`}</code></pre>
    <p>{`That’s made possible by the way CSS Modules are compiled — by using require or import to load the file from JavaScript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import styles from './button.css';

class Button extends React.Component {
  render() {
    return <input type="button" className={styles.normal}>;
  }
}
`}</code></pre>
    <p>{`The actual class names are generated automatically and are guaranteed to be unique. Your souce code may look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<input type="button" class="components_button_normal_acf7488">
`}</code></pre>
    <p>{`When importing the CSS Module from a JS Module, it exports an object with all mappings from local names to global names.`}</p>
    <h2>{`Scoped Selectors`}</h2>
    <p>{`In CSS Modules, selectors are scoped by default.`}</p>
    <p>{`CSS Module semantics ensure that classes are locally scoped to the component and don't collide with other classes in the global scope.`}</p>
    <p>{`In the following example, the component uses two classes, `}<inlineCode parentName="p">{`.root`}</inlineCode>{` and `}<inlineCode parentName="p">{`.text`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import styles from './scoped-selectors.css';

class ScopedSelectors extends React.Component {

  render() {
    return (
      <div className={styles.root}>
        <p className={styles.text}>Scoped Selectors
      </div>
    );
  }

};

export default class ScopedSelectors;
`}</code></pre>
    <p>{`Let's look at the CSS file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  border: 2px solid #c7254e;
  padding: 0 20px;
  margin: 0 6px;
  max-width: 246px;
  border-radius: 10px;
}
.text {
  color: #c7254e;
  font-size: 24px;
  font-family: helvetica, arial, sans-serif;
  font-weight: 600;
  padding-top: 15px;
}
`}</code></pre>
    <p>{`The output will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
  <div class="container">
    <div class="text">Scoped Selectors</div>
  </div>
</div>
`}</code></pre>
    <h2>{`Global Selectors`}</h2>
    <p>{`Global selectors are still available but should be used sparingly. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import styles from './global-selectors.css';

class GlobalSelectors extends React.Component {

  render() {
    return (
      <div className={ styles.root }>
        <p className="text">Global Selectors
      </div>
    );
  }
};

export default GlobalSelectors;
`}</code></pre>
    <p>{`Let's look at the CSS file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`.root {
  border: 2px solid brown;
  padding: 0 20px;
  margin: 0 6px;
  max-width: 234px;
  border-radius: 10px;
}
.root :global .content {
  color: #00ACEE;
  font-size: 24px;
  font-family: helvetica, arial, sans-serif;
  font-weight: 600;
}
`}</code></pre>
    <p>{`The output will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
  <div class="root">
    <div class="content">Global Selectors</div>
  </div>
</div>
`}</code></pre>
    <h2>{`Class Composition`}</h2>
    <p>{`Composition promotes better separation of markup and style using semantics that would be hard to achieve without CSS Modules.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`.classB {
  composes: classA from "./style.css";
}
`}</code></pre>
    <Message type="warn" title="" content="Make sure not to define different values for the same property in multiple class names when they are composed to a single class." mdxType="Message" />
    <p>{`Both of the components below have `}<strong parentName="p">{`locally scoped CSS`}</strong>{` that is composed from a common set of CSS Modules.`}</p>
    <p>{`Since CSS Modules can be composed, the resulting markup is optimised by reusing classes between components.`}</p>
    <h3>{`styles/layout.css`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  border: 2px solid brown;
  padding: 0 20px;
  margin: 0 6px;
  max-width: 234px;
  border-radius: 10px;
}
`}</code></pre>
    <h3>{`styles/wording.css`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
  color: #00ACEE;
  font-size: 24px;
  font-family: helvetica, arial, sans-serif;
  font-weight: 600;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.root {
  composes: box from "styles/layout.css";
  border-color: red;
}

.root .content {
  composes: content from "styles/wording.css":
}
`}</code></pre>
    <p>{`You compose multiple classes with `}<inlineCode parentName="p">{`composes: classNameA classNameB;`}</inlineCode>{`.`}</p>
    <h2>{`Current Implementations`}</h2>
    <p>{`Webpack's `}<inlineCode parentName="p">{`css-loader`}</inlineCode>{` and `}<a parentName="p" {...{
        "href": "https://github.com/gajus/react-css-modules"
      }}>{`react-css-modules`}</a>{` are few of the implementations for CSS Modules.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      